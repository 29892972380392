.contact-icons {
  display: flex;
  width: 100%;
  justify-content: center;
  z-index: 30;

  h3 {
    font-size: 1rem;
  }

  &__icon {
    width: 45px;
    height: 45px;
    margin: 10px;
    z-index: 30;

    &:hover {
      transition-duration: 0.2s;
      transform: scale(1.1);
      cursor: pointer;
    }

    img {
      width: 100%;
      height: 100%;
    }

    &__phone {
      display: none;

      @media screen and (max-width: 500px) {
        display: block;
      }
    }
  }
}
