.add-image {
  &__container {
    position: relative;
    height: 50px;
    width: 100%;
    max-width: 500px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid lightgray;
  }
  &__input {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 0;
  }
  &__value {
    font-size: 14px;
    margin-left: 10px;
  }

  &__button {
    width: 90px;
    height: 23px;
    padding: 0;

    @media screen and (max-width: 700px) {
      font-size: 0.7rem;
    }
  }
}
