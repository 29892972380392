.loading-spinner {
  display: inline-block;
  background-image: url("../imgs/icon-loading-spinner.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 40px;
  height: 40px;
  width: 100%;
  opacity: 0.75;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
}
