.button {
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  transition: all 0.5s;
  cursor: pointer;

  &--default {
    background: #54426a;
    color: white;
    box-shadow: 1px 1px 3px rgba(34, 34, 34, 0.5);
  }

  &--alternate {
    background: white;
    border: 2px solid #54426a;
    color: #54426a;
    font-weight: 400;
  }

  &:hover {
    padding: 8px 27px;
  }

  &:disabled {
    background: grey;
    cursor: not-allowed;
  }

  @media screen and (max-width: 700px) {
    width: 100%;
    height: 50px;
    font-size: 1rem;
  }
}
