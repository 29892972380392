.workshop {
  width: 100vw;
  height: 90vh;
  max-height: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;

  @media screen and (max-width: 850px) {
    max-height: none;
    flex-direction: column;
    height: auto;
  }

  @media screen and (max-height: 500px) {
    padding-top: 80px;
    max-height: none;
    flex-direction: column;
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 80px 0;
  }

  &__inner {
    width: 50%;
    height: auto;
    right: 0;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    text-align: left;

    @media screen and (max-width: 850px) {
      width: 85%;
      padding-bottom: 60px;
    }

    @media screen and (max-height: 500px) {
      margin-bottom: 50px;
      width: 80%;
    }

    @media screen and (max-width: 500px) {
      align-items: center;
    }

    p {
      margin: 0;
      width: 90%;

      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 50px;

    p {
      @media screen and (max-height: 500px) {
        width: 100%;
        text-align: justify;
      }
    }

    @media screen and (max-width: 850px) {
      margin-left: 0;
    }

    @media screen and (max-height: 500px) {
      margin-left: 0;
    }

    @media screen and (max-width: 500px) {
      width: 100vw;
      margin-left: 2vw;
    }

    @media screen and (max-width: 500px) {
      align-items: center;
    }
  }

  &__images {
    &.slideshow {
      z-index: 0;
      list-style: none;
      padding: 0;
      width: 120%;
      height: 100%;
      margin-top: 0;
      -ms-transform: skew(10deg, 0deg) !important;
      -webkit-transform: skew(10deg, 0deg) !important;
      transform: skew(10deg, 0deg) !important;
      left: -100px;
      -webkit-transform: translate3D(0, 0, 0);
      backface-visibility: hidden;
      background-color: #54426a;

      li {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: 0;
        position: absolute;
        height: 100%;
        opacity: 0;
        right: 10vw;
        top: auto;
        left: auto;
        padding: 10px;
        transform-origin: bottom;
        width: 100%;
        padding-bottom: 10px;
        animation: imageAnimation 30s linear infinite;
        -webkit-transform: translate3D(0, 0, 0);
        backface-visibility: hidden;
        background-color: #54426a;

        &:nth-child(2) {
          animation-delay: 6s;
        }

        &:nth-child(3) {
          animation-delay: 12s;
        }

        &:nth-child(4) {
          animation-delay: 18s;
        }

        &:nth-child(5) {
          animation-delay: 24s;
        }
      }
    }

    &__container {
      height: 400px;
      width: 50%;
      max-width: 700px;
      overflow: hidden;
      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg) !important;
      -webkit-transform: skew(-10deg, 0deg) !important;
      transform: skew(-10deg, 0deg) !important;
      box-shadow: -10px 10px 0 0 #54426a;
      position: absolute;
      right: -30px;
      background-color: #54426a;

      @media screen and (max-width: 850px) {
        width: 85%;
        margin-left: 15%;
        position: relative;
      }

      @media screen and (max-width: 500px) {
        height: 250px;
      }

      @media screen and (max-height: 500px) {
        width: 100%;
        margin-left: 20vw;
      }
    }
  }
}

.scroll-message {
  position: fixed;
  bottom: 15px;
  width: 100%;
  text-align: center;
  opacity: 0.3;
  color: rgb(29, 29, 29);
  margin: 0;
  font-size: 18px;
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
    animation-timing-function: ease-in;
  }

  4% {
    opacity: 1;

    animation-timing-function: ease-out;
  }

  28% {
    opacity: 1;
  }

  30% {
    opacity: 0;
    transform: scale(1.1);
  }
}
