.events {
  width: 100vw;
  height: 100vh;
  padding-top: 20px;
  max-height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;

  @media screen and (max-width: 1025px) {
    padding-top: 80px;
  }

  @media screen and (max-width: 900px) {
    max-height: none;
    height: auto;
  }

  @media screen and (max-height: 500px) {
    height: 600px;
  }

  &__list {
    width: 100%;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 900px) {
      flex-direction: column;
      align-items: center;
    }
  }
}

.event {
  width: 30%;
  max-width: 350px;
  min-height: 450px;
  max-height: 550px;
  border: 0.5px solid #cfcfcf;
  margin: 1%;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(73, 73, 73, 0.3);

  @media screen and (max-width: 1025px) {
    max-width: none;
  }

  @media screen and (max-width: 900px) {
    width: 90vw;
    display: flex;
    height: 250px;
    max-height: unset;
    min-height: unset;
  }

  @media screen and (max-width: 600px) {
    display: inline-block;
    margin: 10px;
    max-width: 350px;
    height: unset;
  }

  &__image-container {
    width: 100%;
    height: 250px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
      width: 50%;
    }

    @media screen and (max-width: 600px) {
      width: 100%;
      max-width: unset;
      height: 250px;
    }

    & img {
      height: 100%;
      max-width: 100%;
    }
  }

  &__content {
    @media screen and (max-width: 900px) {
      height: auto;
      padding: 10px;
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    margin: 10px 0;
    padding-left: 5px;
    padding-right: 10px;
  }

  &__date {
    width: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h2 {
      margin: 0;
      color: black;
      font-weight: 400;
    }

    & h4 {
      margin: 0;
      color: rgb(173, 28, 28);
    }
  }

  &__name {
    width: 80%;

    & h3 {
      font-size: 1.4rem;
      margin: 0;
      font-weight: 400;
      color: black;
      font-family: Arial, Helvetica, sans-serif;
    }
  }

  &__location,
  &__time,
  &__price {
    display: flex;
    align-items: center;
    margin: 5% 2px;

    @media screen and (max-height: 500px) {
      margin: 0 2px;
      height: 30px;
    }
  }

  &__details {
    padding-left: 5px;
    padding-right: 5px;

    @media screen and (max-height: 500px) {
      margin: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 20px;
    width: 20px;
    margin: 0 10px;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  & p {
    font-size: 1rem;
    color: black;
    font-family: Arial, Helvetica, sans-serif;
    margin: 0;
    line-height: 1.1rem;
    text-align: left;
    width: 85%;
  }
}
