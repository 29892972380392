.exams {
  width: 100vw;
  height: 100vh;
  max-height: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  margin-top: 60px;

  @media screen and (max-width: 1025px) {
    max-height: none;
    height: auto;
    flex-direction: column;
  }

  @media screen and (max-height: 500px) {
    height: auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 20px 70px;
    width: 50%;

    @media screen and (max-width: 1025px) {
      margin: 70px 0 70px 40px;
      width: 100%;
      margin: 0 0 80px 0;
      align-items: center;
    }

    p {
      width: 90%;
    }
  }

  &__image {
    height: 70%;
    box-shadow: -10px 10px 0 0 #54426a;
    margin-right: 70px;
    overflow: hidden;

    @media screen and (max-width: 1025px) {
      height: 500px;
      margin-right: 10px;
    }

    @media screen and (max-width: 500px) {
      height: 550px;
    }

    img {
      height: 102%;
    }
  }

  &__boards {
    max-width: 600px;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

    img {
      width: 45%;
      max-width: 200px;
      border-radius: 5px;
    }

    @media screen and (max-width: 700px) {
      width: 85vw;
    }
  }
}
