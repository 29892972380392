.about {
  width: 100vw;
  height: 100vh;
  max-height: 700px;
  padding-top: 5vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;

  @media screen and (max-width: 1100px) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }

  @media screen and (max-height: 500px) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }

  @media screen and (max-width: 500px) {
    padding-top: 30px;
  }

  &__content {
    width: 45%;
    margin: 50px;

    @media screen and (max-width: 1100px) {
      width: 85vw;
    }

    @media screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      align-items: center;

      h1 {
        margin-bottom: 10px;
      }
    }
  }

  .title {
    width: 130px;
  }

  &__images {
    &.slideshow {
      z-index: 0;
      list-style: none;
      padding: 0;
      width: 130%;
      height: 100%;
      margin-top: 0;
      -ms-transform: skew(10deg, 0deg) !important;
      -webkit-transform: skew(10deg, 0deg) !important;
      transform: skew(10deg, 0deg) !important;
      -webkit-transform: translate3D(0, 0, 0);
      backface-visibility: hidden;

      li {
        background-size: cover;
        background-repeat: no-repeat;
        z-index: 0;
        position: absolute;
        height: 100%;
        opacity: 0;
        right: 10vw;
        top: auto;
        left: auto;
        padding: 10px;
        transform-origin: bottom;
        width: 100%;
        padding-bottom: 10px;
        animation: imageAnimation 30s linear infinite;
        -webkit-transform: translate3D(0, 0, 0);
        backface-visibility: hidden;
        background-color: white;

        &:nth-child(2) {
          animation-delay: 6s;
        }

        &:nth-child(3) {
          animation-delay: 12s;
        }

        &:nth-child(4) {
          animation-delay: 18s;
        }

        &:nth-child(5) {
          animation-delay: 24s;
        }
      }
    }

    &__container {
      height: 400px;
      width: 50%;
      max-width: 700px;
      overflow: hidden;
      transform-origin: bottom left;
      -ms-transform: skew(-10deg, 0deg) !important;
      -webkit-transform: skew(-10deg, 0deg) !important;
      transform: skew(-10deg, 0deg) !important;
      box-shadow: -10px 10px 0 0 #54426a;
      position: absolute;
      right: -30px;

      @media screen and (max-width: 1100px) {
        width: 85%;
        margin-left: 33%;
        position: relative;
      }

      @media screen and (max-width: 750px) {
        margin-left: 75px;
      }

      @media screen and (max-width: 500px) {
        height: 250px;
        margin-left: 0;
        width: 90%;
      }

      @media screen and (max-height: 500px) {
        width: 100%;
        margin-left: 20vw;
      }
    }
  }
}
