.shows {
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding-top: 80px;
  height: auto;
}

.show {
  width: 100%;
  height: 90vh;
  max-height: 1200px;
  display: flex;
  overflow: hidden;
  box-sizing: border-box;
  border-bottom: 10px solid white;

  @media screen and (max-width: 1025px) {
    max-height: 700px;
  }

  @media screen and (max-width: 900px) {
    max-height: none;
    height: auto;
    flex-direction: column;
  }

  p {
    color: white;
    font-size: 1rem;
  }

  &--right {
    justify-content: flex-end;

    p {
      text-align: right;
    }
  }

  &--left {
    justify-content: flex-start;

    p {
      text-align: left;
    }
  }

  &__section {
    position: absolute;
    z-index: 2;
    width: 55%;
    max-width: 1000px;
    height: 100%;
    max-height: 1200px;
    background: rgba(84, 66, 106, 0.9);
    -ms-transform: skew(-10deg, 0deg) !important;
    -webkit-transform: skew(-10deg, 0deg) !important;
    transform: skew(-10deg, 0deg) !important;

    @media screen and (max-width: 1025px) {
      max-height: 700px;
    }

    @media screen and (max-width: 900px) {
      position: relative;
      width: 100vw;
      height: auto;
      display: flex;
      justify-content: center;
      -ms-transform: skew(0deg, 0deg) !important;
      -webkit-transform: skew(0deg, 0deg) !important;
      transform: skew(0deg, 0deg) !important;
      order: 2;
    }

    &--right {
      transform-origin: bottom left;
    }

    &--left {
      transform-origin: top right;
    }
  }

  &__content {
    -ms-transform: skew(10deg, 0deg) !important;
    -webkit-transform: skew(10deg, 0deg) !important;
    transform: skew(10deg, 0deg) !important;
    margin: 0 20%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 900px) {
      -ms-transform: skew(0deg, 0deg) !important;
      -webkit-transform: skew(0deg, 0deg) !important;
      transform: skew(0deg, 0deg) !important;
      width: 85vw;
      margin: 3vh 0;
      justify-content: center;
      align-items: center !important;
      height: auto;
    }

    &--right {
      align-items: flex-end;
    }

    &--left {
      align-items: flex-start;
    }

    .title {
      color: white;
      text-align: right;
      border-bottom: 2px solid white;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0;

      @media screen and (max-width: 1025px) {
        font-size: 1.3rem;
      }
    }

    p {
      @media screen and (max-width: 900px) {
        text-align: justify;
      }
    }
  }

  &__images.slideshow {
    z-index: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 1200px;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      height: 50vh;
      order: 1;
    }

    @media screen and (max-width: 900px) {
      min-height: 300px;
    }

    li {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 0;
      position: absolute;
      opacity: 0;
      left: auto;
      padding: 0;
      width: 100%;
      height: 102%;
      transform-origin: bottom;
      animation: imageAnimation 30s linear infinite;
    }

    li:nth-child(2) {
      animation-delay: 6s;
    }

    li:nth-child(3) {
      animation-delay: 12s;
    }

    li:nth-child(4) {
      animation-delay: 18s;
    }

    li:nth-child(5) {
      animation-delay: 24s;
    }
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
    animation-timing-function: ease-in;
  }

  4% {
    opacity: 1;

    animation-timing-function: ease-out;
  }

  28% {
    opacity: 1;
  }

  30% {
    opacity: 0;
    transform: scale(1.1);
  }
}
