.edit-workshop {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  &__container {
    width: 90%;
  }

  &__section {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;

    input {
      max-width: 500px;
    }
  }

  button {
    width: 90%;
    max-width: 150px;

    @media screen and (max-width: 500px) {
      max-width: none;
    }
  }

  @media screen and (max-width: 800px) {
    margin-bottom: 50px;
  }
}
