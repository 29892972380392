.input {
  padding: 15px 0;
  margin-bottom: 20px;
  font-size: 0.9rem;
  font-weight: 400;
  border: none;
  border-bottom: 1px solid #54426a;
  width: 100%;

  &--textarea {
    min-height: 120px;
    line-height: 2rem;
    border: 1px solid #54426a;
    padding: 10px;
    border-radius: 5px;
    box-sizing: border-box;
    margin-top: 20px;

    @media screen and (max-width: 500px) {
      min-height: 300px;
    }
  }

  &:focus {
    outline: none;
  }

  &::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #54426a;
    opacity: 1; /* Firefox */
    font-weight: 300;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #54426a;
    font-weight: 300;
  }

  &::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #54426a;
    font-weight: 300;
  }

  @media screen and (max-width: 900px) {
    background: none;
    width: 100%;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.2rem;
  }

  @media screen and (max-height: 500px) {
    font-size: 1.2rem;
    background: none;
    width: 100%;
  }
}
