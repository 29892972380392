.timetable {
  width: 100vw;
  height: 100vh;
  max-height: 650px;
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  @media screen and (max-width: 800px) {
    justify-content: flex-start;
    height: auto;

    &__options__mobile {
      width: 100%;
      justify-content: space-around;
      margin: 2vh 0;
      z-index: 2;

      .react-select {
        &-container {
          width: 48%;
          float: left;
          margin: 0 1%;
        }

        &__control {
          border: none;
          border-radius: 0;
          padding: 10px;
        }

        &__placeholder {
          color: #54426a;
          font-size: 1.2rem;
        }

        &__single-value {
          color: #54426a;
          font-size: 1.2rem;
          font-weight: 300;
        }

        &__option {
          height: 60px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  @media screen and (max-height: 500px) {
    height: 600px;
  }

  h1 {
    margin-bottom: 0;
  }

  &__content {
    width: 100vw;
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;

    @media screen and (max-width: 800px) {
      display: none;
    }

    &__mobile {
      display: none;

      @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        height: auto;
        width: 100vw;
        padding: 2vh 5vw;
        box-sizing: border-box;
      }

      table {
        width: 100%;
      }
    }
  }

  tr {
    height: 35px;
  }
}

.days {
  width: 18%;
  display: flex;
  margin-top: 30px;
  cursor: pointer;

  &--selected {
    background-color: #54426a;
    transform-origin: top right;
    box-shadow: 3px 3px 0px rgb(94, 94, 94);

    h4 {
      color: white;

      @media screen and (max-width: 800px) {
        padding-left: 15%;
      }
    }
  }

  h4 {
    -ms-transform: skew(10deg, 0deg) !important;
    -webkit-transform: skew(10deg, 0deg) !important;
    transform: skew(10deg, 0deg) !important;
    padding-left: 30%;
    padding-top: 2px;

    @media screen and (max-height: 500px) {
      padding-left: 20%;
    }
  }

  ul {
    list-style: none;
    width: 100%;
    padding-left: 0;
  }

  li {
    height: 60px;
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    transition: all 0.3s;
    transform-origin: top right;
    -ms-transform: skew(-10deg, 0deg) !important;
    -webkit-transform: skew(-10deg, 0deg) !important;
    transform: skew(-10deg, 0deg) !important;

    @media screen and (max-height: 500px) {
      height: 45px;
    }
  }
}

.table__container {
  width: 82%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  table {
    width: 90%;
    text-align: center;
    border-collapse: collapse;
    border: none;
  }

  tr {
    border-bottom: 1px solid rgba(128, 128, 128, 0.2);

    @media screen and (max-width: 800px) {
      height: 45px;
    }
  }
}

.column__times {
  width: 10%;
}

.row__time {
  @media screen and (max-width: 800px) {
    text-align: left;
    font-size: 1rem;
    font-weight: 300;
  }

  td {
    width: 30%;
  }
}

td {
  font-size: 1rem;
  border: none;

  @media screen and (max-width: 800px) {
    text-align: right;
    font-size: 1.1rem;
    font-weight: 400;
    border-bottom: 1px solid rgb(236, 236, 236);
  }
}

thead td {
  font-size: 1.2rem;
  height: 60px;
  width: 30%;
}

.no-classes {
  align-self: center;
  color: rgb(184, 184, 184);
  text-align: center;
}

:focus {
  outline: 0;
  outline-color: transparent;
  outline-style: none;
}
