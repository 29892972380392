.contact {
  width: 100vw;
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  background-color: white;
  padding-left: 80px;

  @media screen and (max-width: 900px) {
    padding-left: 0;
    justify-content: center;
  }

  @media screen and (max-height: 500px) {
    padding-left: 0;
    height: auto;
    max-height: none;
    justify-content: center;
  }

  &__form {
    width: 40%;
    min-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media screen and (max-width: 900px) {
      z-index: 1;
      width: 60vw;
      align-items: center;
    }

    @media screen and (max-width: 500px) {
      width: 85vw;
    }

    @media screen and (max-height: 500px) {
      width: 85vw;
      padding-left: 0;
      justify-content: center;
      height: 450px;
      z-index: 1;
      width: 60vw;
      align-items: center;
    }

    form {
      width: 100%;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 900px) {
        align-items: center;
      }

      @media screen and (max-height: 500px) {
        align-items: center;
      }
    }
  }

  img {
    position: absolute;
    right: -25vh;
    height: 50vw;
    max-height: 500px;
    bottom: 60px;

    @media screen and (max-width: 900px) {
      opacity: 0.07;
      right: auto;
      left: auto;
      bottom: 50px;
      z-index: 0;
      width: auto;
      height: 500px;
    }

    @media screen and (max-width: 500px) {
      bottom: 150px;
    }

    @media screen and (max-height: 500px) {
      opacity: 0.07;
      right: auto;
      left: auto;
      bottom: 70px;
      z-index: 0;
      position: absolute;
      width: auto;
      height: 450px;
      max-height: none;
    }
  }

  button {
    width: 150px;
    height: 45px;
    font-size: 0.9rem;
    margin: 30px 0 50px;

    &:hover {
      width: 160px;

      @media screen and (max-width: 500px) {
        width: 85vw;
      }

      @media screen and (max-height: 500px) {
        width: 85vw;
      }
    }

    @media screen and (max-width: 500px) {
      width: 85vw;
      font-size: 1.2rem;
      height: 50px;
    }

    @media screen and (max-height: 500px) {
      width: 100%;
      font-size: 1.2rem;
    }
  }
}

footer {
  width: 100vw;
  height: 80px;
  background-color: #54426a;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 900px) {
    max-height: 60px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    flex-direction: column;
    max-height: none;
    align-items: flex-start;
    height: auto;
    padding: 5%;
  }

  @media screen and (max-height: 500px) {
    width: 100%;
    flex-direction: column;
    max-height: none;
    align-items: flex-start;
    height: auto;
    padding: 10px;
  }

  p {
    color: white;
    font-size: 0.7rem;
    line-height: 1.2rem;

    @media screen and (max-width: 500px) {
      font-size: 1rem;
      line-height: 1rem;
      text-align: center;
    }

    @media screen and (max-height: 500px) {
      font-size: 1rem;
      line-height: 1rem;
      text-align: center;
    }
  }

  .copyright-image {
    max-height: 0.7rem;
  }
}
