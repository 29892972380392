.team {
  width: 100vw;
  height: 100vh;
  padding-top: 70px;
  max-height: 750px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;

  @media screen and (max-width: 500px) {
    max-height: none;
    height: auto;
  }

  p {
    line-height: 2.2rem;
    text-align: justify;
    margin: 10px auto;

    @media screen and (max-width: 1025px) {
      margin: 10px 50px;
    }

    @media screen and (max-width: 500px) {
      margin: 10px 0;
    }
  }

  &__list {
    margin-top: 10px;
    overflow-x: scroll;
    padding-bottom: 20px;

    @media screen and (min-height: 770px) {
      margin-top: 50px;
    }

    &__inner {
      display: flex;
    }

    &__container {
      overflow: hidden;
      height: 25vh;
      min-height: 230px;
      width: 100%;
      max-width: 1000px;

      @media screen and (max-width: 1025px) {
        width: 90%;
      }

      @media screen and (max-width: 500px) {
        display: none;
      }
    }
  }

  &__mobile {
    display: none;

    @media screen and (max-width: 500px) {
      display: flex;
      flex-direction: column;
      margin: 0 10vw;
    }
  }

  &-member {
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.5;
    margin: 0 40px;
    transition: all 0.3s;

    &__image {
      border-radius: 50%;
      width: 120px;
      height: 120px;
      max-width: 170px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;

      img {
        min-height: 100%;
        max-height: 110%;
        max-width: 120%;
        transition: all 0.1s;
      }
    }

    &__description {
      margin: 0 5vw;

      @media screen and (min-height: 770px) {
        margin: 10px auto;
        max-width: 1000px;
      }

      @media screen and (max-width: 500px) {
        display: none;
      }
    }

    :hover {
      cursor: pointer;
    }

    h2 {
      opacity: 0;
      transition: all 0.3s;
      margin: 15px 0;
    }

    &__active {
      opacity: 1;

      h2 {
        opacity: 1;
      }
    }

    &__mobile {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 30px;

      &__header {
        display: flex;
        width: 88vw;
      }

      &__image {
        width: 70px;
        height: 70px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 5vw;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: auto;
          max-height: 100%;
        }
      }
    }
  }
}
