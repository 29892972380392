.update-events {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;

  &__form {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;

    &__item {
      width: 30%;
      min-width: 350px;
      margin: 1.3%;
      box-shadow: 0 1px 3px rgb(187, 187, 187);
      border-radius: 10px;
      box-sizing: border-box;
      padding: 20px;
      position: relative;

      @media screen and (max-width: 500px) {
        width: 100%;
        min-width: unset;
      }
    }
  }

  h3 {
    margin: 10px 0;
  }

  .delete-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 15px;
    right: 5px;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
