.edit-timetable {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  &__container {
    width: 90%;
  }

  &__buttons {
    display: flex;
    width: 300px;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 90%;
      height: 120px;
      justify-content: space-between;
    }
  }

  tr {
    height: 50px;
  }

  .input {
    margin: 5px;
    width: 90%;
    text-align: center;
    @media screen and (max-width: 700px) {
      width: 98%;
    }
  }

  .timetable__content {
    margin-bottom: 30px;
  }

  .row__time {
    @media screen and (max-width: 700px) {
      width: 70px;
    }
  }

  .delete-icon {
    width: auto;
    height: auto;
    position: inherit;
    padding-top: 10px;
    cursor: pointer;
  }
}
