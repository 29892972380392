.singing {
  width: 100vw;
  height: 100vh;
  max-height: 800px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: white;
  position: relative;

  @media screen and (max-width: 1025px) {
    max-height: 600px;
    margin: 50px 0;
  }

  @media screen and (max-width: 850px) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }

  @media screen and (max-height: 550px) {
    flex-direction: column;
    max-height: none;
    height: auto;
  }

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__image {
    width: 45%;
    max-width: 560px;
    height: 80%;
    overflow: hidden;
    transform-origin: bottom left;
    -ms-transform: skew(-8deg, 0deg) !important;
    -webkit-transform: skew(-8deg, 0deg) !important;
    transform: skew(-8deg, 0deg) !important;
    box-shadow: 10px 10px 0 0 #54426a;
    position: absolute;
    left: -115px;

    @media screen and (max-width: 850px) {
      order: 2;
      margin-right: 63vw;
      position: static;
      height: 500px;
      width: 440px;
    }

    @media screen and (max-width: 500px) {
      height: 100vw;
      width: 80vw;
    }

    @media screen and (max-height: 550px) {
      order: 2;
      margin-right: 40vw;
    }

    img {
      min-width: 110%;
      min-height: 100%;
      width: 630px;
      -ms-transform: skew(8deg, 0deg) !important;
      -webkit-transform: skew(8deg, 0deg) !important;
      transform: skew(8deg, 0deg) !important;
      -webkit-transform: translate3D(0, 0, 0);
      backface-visibility: hidden;

      @media screen and (max-width: 1025px) {
        width: 125%;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 45%;
    margin-right: 5vw;
    text-align: right;

    @media screen and (max-width: 1025px) {
      margin-right: 50px;
    }

    @media screen and (max-width: 850px) {
      order: 1;
      width: 85vw;
      margin: 60px 0;
    }

    @media screen and (max-height: 500px) {
      order: 1;
      width: 85vw;
      margin: 60px 0;
    }

    @media screen and (max-width: 500px) {
      margin: 10px 0 20px 0;
    }

    p {
      @media screen and (max-width: 850px) {
        width: 90%;
        margin-top: 0;
      }

      @media screen and (max-height: 500px) {
        width: 90%;
        margin-top: 0;
      }

      @media screen and (max-width: 550px) {
        width: 100%;
      }
    }
  }
}
