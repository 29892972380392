.edit-shows {
  width: 100%;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;

  &__container {
    width: 90%;
    position: relative;
  }

  &__section {
    width: 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 50px;
    position: relative;

    input {
      max-width: 400px;
    }
  }

  &__buttons {
    display: flex;
    width: 300px;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      width: 90%;
      height: 120px;
      justify-content: space-between;
    }
  }

  .delete-icon {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -50px;
    right: 0;
    cursor: pointer;
    transition-duration: 0.2s;

    &:hover {
      transform: scale(1.1);
    }
  }
}
