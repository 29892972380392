.add-user {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5vw;
  box-sizing: border-box;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &__form {
    width: 40%;
    height: 300px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;

    @media screen and (max-width: 800px) {
      width: 300px;
      height: auto;
      padding-bottom: 50px;
      align-items: center;
      text-align: center;
    }
  }

  img {
    min-height: 200px;
    height: 35vw;
  }
}
