* {
  color: #54426a;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

html,
body {
  position: relative;
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;

  @media screen and (min-width: 800px) {
    scroll-behavior: smooth;
  }
}

#root {
  overflow-x: hidden;
}

.home {
  position: relative;
}

a {
  text-decoration: none;
}

.title {
  padding-bottom: 10px;
  border-bottom: 2px solid #54426a;
  font-size: 1.8rem;
  width: auto;

  @media screen and (max-width: 770px) {
    text-align: center;
  }

  @media screen and (max-height: 500px) {
    font-size: 1.8rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1.6rem;
  }
}

p {
  font-size: 1.1rem;
  line-height: 2.5rem;
  max-width: 800px;

  @media screen and (max-width: 770px) {
    text-align: justify;
  }
}

.navAnchor {
  padding-top: 50vh;
}

.edit {
  color: #54426a;
  display: flex;
  align-items: center;
  align-self: flex-end;
  z-index: 1;
  top: 80px;
  margin-right: 20px;

  img {
    height: 70px;
  }
}

.error-message {
  font-size: 0.8rem;
  line-height: 1.5rem;
  margin-top: 20px;
  color: rgb(212, 21, 21);
}
