.privacy {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 5vw;
  box-sizing: border-box;
  text-align: left;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  p, ul {
    width: 100%;
    max-width: 800px
  }

  strong {
    font-weight: 500;
    font-size: 1rem;
  }

  h3 {
    font-weight: 400
  }
}
