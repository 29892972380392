.navbar {
  width: 100vw;
  height: 70px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 10;

  @media screen and (max-width: 700px) {
    background-color: #54426a !important;
  }

  &__logo {
    width: 50px;
    margin-left: 20px;
    padding-top: 3px;
  }

  &__menu {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
    box-sizing: border-box;

    @media screen and (max-width: 1025px) {
      flex-direction: column;
      position: absolute;
      top: 0;
      background-color: #54426a;
      width: 70vw;
      max-width: 350px;
      height: 100vh;
      padding-top: 60px;
      box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.445);
      transition: all 0.3s;
      animation: open-side-nav 0.5s forwards;
    }

    @media screen and (max-height: 500px) {
      width: 40vw;
    }
  }

  &__menu__list {
    display: flex;
    list-style: none;
    align-items: center;
    height: 100%;
    padding-left: 10px;
    margin: 0;

    @media screen and (max-width: 1025px) {
      flex-direction: column;
      align-items: flex-end;
      height: auto;
      width: 90%;
      padding-right: 10%;
      padding-left: 0;
      transition: all 0.3s 0.2s;
    }

    @media screen and (max-height: 500px) {
      height: 75%;
    }
  }

  &__menu__item {
    padding: 0 0.3vw;
    margin: 0 0.6vw;
    height: 60%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media screen and (max-width: 1025px) {
      height: 6.5vh;
      max-height: 60px;
      width: 90%;
      margin-bottom: 1.3vh;
    }

    @media screen and (max-height: 500px) {
      height: 25%;
    }

    &:hover {
      cursor: pointer;
    }

    a {
      @media screen and (max-width: 1025px) {
        color: white;
        font-size: 1rem;
      }
    }
  }

  &__auth {
    margin-right: 30px;
    box-sizing: border-box;

    @media screen and (max-width: 1025px) {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-bottom: 20px;
      position: relative;
      transition: all 0.3s 0.2s;
      width: 80%;
      height: 25%;
    }
    & button {
      @media screen and (max-width: 1025px) {
        background: none;
        width: 100%;
        height: 50px;
        border: 2px solid white;
        box-shadow: none;
        font-size: 1rem;
      }

      @media screen and (max-height: 500px) {
        height: 40px;
      }
    }

    & input {
      background: none;
      padding: 8px 10px;
      border: none;
      border-bottom: 1px solid #54426a;
      margin-right: 20px;
      color: #54426a;
    }

    &--signed-in {
      display: flex;
      align-items: center;

      @media screen and (max-width: 1025px) {
        flex-direction: column;
      }

      &__inner {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 1025px) {
          width: 100%;
        }
        @media screen and (max-height: 500px) {
          margin: 5px;
        }

        span {
          font-size: 0.9rem;
          margin-right: 10px;

          @media screen and (max-width: 1025px) {
            color: white;
            font-size: 1.2rem;
          }

          @media screen and (max-height: 500px) {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  &__current-section {
    border-bottom: 1px solid #54426a;

    @media screen and (max-width: 1025px) {
      border-bottom: none;
    }
  }

  &__menu-icon {
    height: 25px;
    display: none;
    cursor: pointer;
    z-index: 10;
    position: absolute;
    right: 15px;

    @media screen and (max-width: 1025px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 35px;
    }

    &__line {
      display: flex;
      background-color: #54426a;
      width: 100%;
      height: 2px;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      -o-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
      cursor: pointer;

      @media screen and (max-width: 700px) {
        background-color: white;
      }
    }
  }
}

@media screen and (max-width: 1025px) {
  .line {
    -webkit-transform-origin: right;
    -moz-transform-origin: right;
    -o-transform-origin: right;
    transform-origin: right;
    &-1 {
      top: 0;
    }

    &-2 {
      transition: all 0.3s;
    }

    &-3 {
      bottom: 0;
    }
  }

  .open-menu {
    animation: open-menu 0.3s forwards;

    &__1 {
      animation: open-menu-1 0.3s forwards;
    }

    &__2 {
      animation: open-menu-2 0.3s forwards;
    }

    &__3 {
      animation: open-menu-3 0.3s forwards;
    }

    & div {
      background-color: white;
    }
  }

  .close-menu {
    &__1 {
      animation: close-menu-1 0.3s forwards;
    }

    &__2 {
      animation: close-menu-2 0.3s forwards;
    }

    &__3 {
      animation: close-menu-3 0.3s forwards;
    }
  }
}

.add-user-icon {
  width: 25px;
  margin: 5px 10px 0 0;

  @media screen and (max-width: 1025px) {
    margin-right: 0;
    width: 40px;
  }

  @media screen and (max-height: 500px) {
    width: 20px;
  }
}

@keyframes open-side-nav {
  0% {
    right: -30vw;
  }
  100% {
    right: 0;
  }
}

@keyframes open-menu-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-45deg);
    width: 118%;
  }
}

@keyframes open-menu-2 {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes open-menu-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(45deg);
    width: 118%;
  }
}

@keyframes close-menu-1 {
  0% {
    transform: rotate(-45deg);
  }
  100% {
    transform: rotate(0deg);
    width: 100%;
  }
}

@keyframes close-menu-2 {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes close-menu-3 {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
    width: 100%;
  }
}

@keyframes open-menu {
  0% {
    width: 30px;
    height: 25px;
  }
  100% {
    width: 33px;
    height: 30px;
    margin-right: 6px;
  }
}
