.awards {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: auto;
  padding-top: 80px;
  margin: 0 20px;
  box-sizing: border-box;

  &__list {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 900px) {
      flex-direction: row;
      align-items: flex-start;
    }

    @media screen and (max-width: 700px) {
      flex-direction: column;
      max-height: none;
      align-items: center;
    }

    @media screen and (max-height: 500px) {
      flex-direction: column;
      max-height: none;
    }
  }
}

.award {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1000px;
  height: 250px;
  border-radius: 20px;
  box-shadow: 1px 1px 5px rgba(73, 73, 73, 0.3);
  margin: 10px;
  padding: 20px 30px;

  @media screen and (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 40%;
    height: 620px;
  }

  @media screen and (max-width: 700px) {
    width: 80%;
    height: auto;
  }

  @media screen and (max-height: 500px) {
    width: 80%;
  }

  &__image__container {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: 900px) {
      order: 1;
      height: 200px;
      width: 100%;
    }

    img {
      max-height: 90%;
      max-width: 120%;

      @media screen and (max-width: 900px) {
        .awardImageContainer img {
          max-width: none;
        }
      }
    }
  }

  &__content {
    width: 80%;

    h1 {
      margin: 0;
    }

    @media screen and (max-width: 900px) {
      order: 2;
      height: 50%;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 700px) {
      margin-top: 10px;
    }
  }

  &--right {
    text-align: right;
  }

  &--left {
    text-align: left;
  }

  p {
    @media screen and (max-width: 900px) {
      width: 90%;
    }
  }

  h2 {
    margin: 0;
  }
}
