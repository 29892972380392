.header {
  width: 100vw;
  height: 90vh;
  max-height: 1500px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  justify-content: center;
  overflow: hidden;
  padding-top: 80px;

  @media screen and (max-width: 1025px) {
    height: 700px;
  }

  @media screen and (max-width: 600px) {
    max-height: none;
    height: auto;
    padding-top: 15vh;
  }

  &__inner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80%;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
      padding-bottom: 10px;
      height: auto;
    }

    img {
      width: 40vw;
      margin-left: 5vw;

      @media screen and (max-width: 600px) {
        width: 60vw;
        margin-bottom: 20px;
        margin-left: 0;
      }
    }
  }

  &__section {
    width: 45vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;

    @media screen and (max-width: 600px) {
      width: 90vw;
      height: auto;
      margin: 0 5vw;
      align-items: center;
      text-align: center;
    }

    h1 {
      font-size: 5rem;
      margin-top: 20px;
      margin-bottom: 20px;
      @media screen and (max-width: 770px) {
        font-size: 3.2rem;
        margin: 0;
      }
    }

    h2 {
      font-size: 1.5rem;
      margin: 12px 0;
    }
  }

  &__sub {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 20%;
    z-index: 2;

    @media screen and (max-width: 600px) {
      text-align: center;
      margin: 0 5vw;
      padding-bottom: 0;
      height: auto;
    }

    h3 {
      font-size: 0.9rem;
      line-height: 1.5rem;
      margin-top: 2px;
      z-index: 2;

      @media screen and (max-width: 600px) {
        margin: 10px 0;
      }
    }
  }

  &__slideshow {
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    list-style: none;
    margin: 0;
    padding: 0;
    opacity: 0.2;

    @media screen and (max-width: 600px) {
      display: none;
    }

    li {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      background-size: cover;
      background-position: top;
      background-repeat: no-repeat;
      opacity: 0;
      z-index: -1;
      animation: imageAnimation 26s linear infinite;
    }

    li:nth-child(1) {
      animation-delay: 1s;
    }

    li:nth-child(2) {
      animation-delay: 6s;
    }

    li:nth-child(3) {
      animation-delay: 11s;
    }

    li:nth-child(4) {
      animation-delay: 16s;
    }

    li:nth-child(5) {
      animation-delay: 21s;
    }
  }
}

@keyframes imageAnimation {
  0% {
    opacity: 0;
    transform: scale(1);
    animation-timing-function: ease-in;
  }

  8% {
    opacity: 1;

    animation-timing-function: ease-out;
  }

  28% {
    opacity: 1;
  }

  30% {
    opacity: 0;
    transform: scale(1.2);
  }
}
